import { SnackbarNotification } from '../../types/SnackbarNotification';

export enum UiActionType {
  SET_SIDEMENU_VISIBILITY = 'ui/SET_SIDEMENU_VISIBILITY',
  ENQUEUE_SNACKBAR = 'ui/ENQUEUE_SNACKBAR',
  REMOVE_SNACKBAR = 'ui/REMOVE_SNACKBAR',
}

export interface SetSideMenuVisibilityAction {
  type: UiActionType.SET_SIDEMENU_VISIBILITY;
  sidemenuVisible: boolean;
}

export interface EnqueueSnackbarAction {
  type: UiActionType.ENQUEUE_SNACKBAR;
  notification: SnackbarNotification;
}

export interface RemoveSnackbarAction {
  type: UiActionType.REMOVE_SNACKBAR;
  key: number;
}

export type UiAction =
  | SetSideMenuVisibilityAction
  | EnqueueSnackbarAction
  | RemoveSnackbarAction;
