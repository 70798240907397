import { withStyles, WithStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import { History } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose, Dispatch } from 'redux';
import { logout } from '../../async-actions/user';
import { setSidemenuVisibilityActionCreator } from '../../redux-modules/ui/action-creators';
import * as routes from '../../routes';
import { AppState } from '../../types/AppState';

type SideMenuObject = {
  label: string;
  icon?: React.ReactElement<{}>;
};

type SideMenuLink = SideMenuObject & {
  path: string;
  onClick?: null;
};

type SideMenuButton = SideMenuObject & {
  onClick: () => any;
  path?: null;
};

const styles = {
  list: {
    width: 250,
  },
};

type DispatchProps = {
  history: History;
  closeSidemenu: () => void;
  openSidemenu: () => void;
  authed: boolean;
  sidemenuVisible: boolean;
  logout: () => void;
};

class SideMenu extends React.Component<
  DispatchProps & WithStyles<typeof styles> & RouteComponentProps<{}>
> {
  authenticatedSideMenu: {
    topSection: (SideMenuLink | SideMenuButton)[];
    bottomSection: (SideMenuLink | SideMenuButton)[];
  } = {
    topSection: [routes.dashboard, routes.viewGames, routes.viewNotes],
    bottomSection: [
      routes.account,
      {
        label: 'Sign out',
        icon: <PowerSettingsNew />,
        onClick: () => {
          this.props.logout();
          this.props.closeSidemenu();
        },
      },
    ],
  };

  unauthenticatedSideMenu: {
    topSection: (SideMenuLink | SideMenuButton)[];
    bottomSection: (SideMenuLink | SideMenuButton)[];
  } = {
    topSection: [routes.landingPage],
    bottomSection: [routes.login, routes.register],
  };

  mapSideMenuObjects = (sideMenuObject: SideMenuLink | SideMenuButton) => {
    let onClickAction;
    if (sideMenuObject.path) {
      onClickAction = () => {
        this.props.history.push(sideMenuObject.path);
        this.props.closeSidemenu();
      };
    } else {
      onClickAction = sideMenuObject.onClick;
      if (onClickAction === undefined || onClickAction === null) onClickAction = () => {};
    }

    return (
      <ListItem button key={sideMenuObject.label} onClick={onClickAction}>
        {sideMenuObject.icon ? <ListItemIcon>{sideMenuObject.icon}</ListItemIcon> : null}
        <ListItemText primary={sideMenuObject.label} />
      </ListItem>
    );
  }

  render = () => {
    const { authed, closeSidemenu, classes, sidemenuVisible, openSidemenu } = this.props;

    const sideMenuToRender = authed ? this.authenticatedSideMenu : this.unauthenticatedSideMenu;

    return (
      <SwipeableDrawer
        open={sidemenuVisible}
        onClose={() => {
          closeSidemenu();
        }}
        onOpen={() => {
          openSidemenu();
        }}
      >
        <div className={classes.list}>
          <List>
            {sideMenuToRender.topSection.map(this.mapSideMenuObjects)}
            <Divider />
            {sideMenuToRender.bottomSection.map(this.mapSideMenuObjects)}
          </List>
        </div>
      </SwipeableDrawer>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    sidemenuVisible: state.ui.sidemenuVisible,
    authed: state.user.authed,
  };
};

const mapDispatchToProps = {
  logout,
  closeSidemenu: () => setSidemenuVisibilityActionCreator(false),
  openSidemenu: () => setSidemenuVisibilityActionCreator(true),
};

// const mapDispatchToProps = (dispatch: Dispatch) => {
//   return {
//     closeSidemenu: () => {
//       dispatch(setSidemenuVisibilityActionCreator(false));
//     },
//     openSidemenu: () => {
//       dispatch(setSidemenuVisibilityActionCreator(true));
//     },
//   };
// };

export default compose<React.ComponentClass>(
  withStyles(styles),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SideMenu);
