import * as React from 'react';
import { matchPath, Switch } from 'react-router-dom';
import { logView } from '../../setup/google-analytics';
import RouteComponents from './RouteComponents';

type Props = {
  matchedRoutePrefix: string;
  defaultWindowTitle: string;
  currentPath: string;
  children: (
    | typeof RouteComponents.Any
    | typeof RouteComponents.Private
    | typeof RouteComponents.Public)[];
};

class SwitchWithWindowTitle extends React.Component<Props> {
  render = () => {
    const { matchedRoutePrefix, defaultWindowTitle, currentPath, children, ...rest } = this.props;

    const matchingChild = children.find((child) => {
      const isPathMatched = matchPath(currentPath, (child as React.ReactElement).props);

      return isPathMatched !== null;
    });

    const matchingChildElement = matchingChild as React.ReactElement;

    if (matchingChildElement.props && matchingChildElement.props.title) {
      const title = matchingChildElement.props.title;
      switch (typeof title) {
        case 'string':
          document.title = `${matchedRoutePrefix}${title}`;
          break;
        case 'function':
          const matchedPath = matchPath(currentPath, matchingChildElement.props);
          document.title = `${matchedRoutePrefix}${title(matchedPath && matchedPath.params)}`;
          break;
        default:
          break;
      }
    } else {
      if (defaultWindowTitle) document.title = defaultWindowTitle;
    }

    logView(document.title, currentPath);

    return <Switch {...rest}>{children}</Switch>;
  }
}

export default SwitchWithWindowTitle;
