import { WithStyles, withStyles } from '@material-ui/core/styles/index';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Toolbar from '@material-ui/core/Toolbar';

import { default as MenuIcon } from '@material-ui/icons/Menu';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose, Dispatch } from 'redux';
import Logo from '../../components/basic/Logo';
import { setSidemenuVisibilityActionCreator } from '../../redux-modules/ui/action-creators';
import * as routes from '../../routes';
import AppConstants from '../../types/AppConstants';
import { AppState } from '../../types/AppState';
import NavLink from './NavLink';

const styles = {
  flexGrow: {
    flexGrow: 1,
  },
  navbarHeader: {
    // flexGrow: 1,
    color: 'inherit',
    textDecoration: 'none',
    fontFamily: 'Advent Pro',
    letterSpacing: '1.5vw',
    textShadow: '0 0 10px #fff',
    fontSize: '1.2rem',
    display: 'flex',
    alignItems: 'center',
  },
  navbarContents: {
    height: '100%',
  },
};

type Props = {
  authed: boolean;
  currentPathname: string;
  openSideMenu: () => void;
} & WithStyles<typeof styles>;

const MobileNavbar = ({ openSideMenu, currentPathname, authed, classes }: Props) => {
  let firstBreadcrumb: string | false =
    currentPathname === '/' ? '/' : currentPathname.split('/')[1];

  if (authed) {
    return (
      <Toolbar className={classes.navbarContents}>
        <Link to='/' className={classes.navbarHeader}>
          <Logo />
          {AppConstants.appTitle}
        </Link>
        <div className={classes.flexGrow}/>
        <MenuIcon onClick={openSideMenu} />
      </Toolbar>
    );
  }

  if (!['login', 'register'].includes(firstBreadcrumb)) firstBreadcrumb = false;

  return (
    <Toolbar className={classes.navbarContents}>
      <Link to='/' className={classes.navbarHeader}>
        <Logo />
        {AppConstants.appTitle}
      </Link>
      <div className={classes.flexGrow}/>
      <Tabs value={firstBreadcrumb}>
        <NavLink
          to={routes.login.path}
          value={routes.login.label.toLowerCase()}
          label={routes.login.label}
        />
        <NavLink
          to={routes.register.path}
          value={routes.register.label.toLowerCase()}
          label={routes.register.label}
        />
      </Tabs>
    </Toolbar>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    authed: state.user.authed,
    currentPathname: state.router.location.pathname,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    openSideMenu: () => {
      dispatch(setSidemenuVisibilityActionCreator(true));
    },
  };
};

export default compose<React.FunctionComponent>(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(MobileNavbar);
