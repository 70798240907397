import { withStyles, WithStyles } from '@material-ui/core';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import * as React from 'react';
import { appTheme } from '../../Theme';

const styles = (theme: typeof appTheme) => ({
  root: {
    color: 'black',
  },
});

type Props = {
  children: React.ReactNode;
} & WithStyles<typeof styles>;

const SnackbarProvider = ({ children, classes }: Props) => (
  <NotistackSnackbarProvider maxSnack={3} ContentProps={{ classes }}>
    {children}
  </NotistackSnackbarProvider>
);

export default withStyles(styles)(SnackbarProvider);
