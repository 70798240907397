import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { default as thunk } from 'redux-thunk';
import { createRootReducer } from '../../redux-modules';
import { AppState } from '../../types/AppState';

export default function configureStore(initialState: AppState, history: History) {
  const enhancer = compose(applyMiddleware(thunk, routerMiddleware(history)));
  return createStore(createRootReducer(history), initialState, enhancer);
}
