import { FirestoreMetadata } from '../../types/firestore/FirestoreMetadata';
import { SingleGame } from '../../types/smash/SingleGame';

export enum GamesActionType {
  REQUEST_GAMES = 'games/REQUEST_GAMES',
  REQUEST_GAME = 'games/REQUEST_GAME',
  ADD_GAME = 'games/ADD_GAME',
  RECEIVE_GAME = 'games/RECEIVE_GAME',
  RECEIVE_GAMES = 'games/RECEIVE_GAMES',
  UPDATE_GAME = 'games/UPDATE_GAME',
  DELETE_GAME = 'games/DELETE_GAME',
}

export interface RequestGamesAction {
  type: GamesActionType.REQUEST_GAMES;
}

export interface RequestGameAction {
  type: GamesActionType.REQUEST_GAME;
}

export interface ReceiveGameAction {
  type: GamesActionType.RECEIVE_GAME;
  game: SingleGame & Partial<FirestoreMetadata>;
}

export interface ReceiveGamesAction {
  type: GamesActionType.RECEIVE_GAMES;
  games: {
    [key: string]: SingleGame & FirestoreMetadata;
  };
}

export interface UpdateGameAction {
  type: GamesActionType.UPDATE_GAME;
  id: string;
  game: SingleGame;
}

export interface AddGameAction {
  type: GamesActionType.ADD_GAME;
  id: string;
  game: SingleGame & Partial<FirestoreMetadata>;
}

export interface DeleteGameAction {
  type: GamesActionType.DELETE_GAME;
  id: string;
}

export type GamesAction =
  | RequestGamesAction
  | RequestGameAction
  | ReceiveGamesAction
  | UpdateGameAction
  | AddGameAction
  | DeleteGameAction
  | ReceiveGameAction;
