import { Action } from 'redux';
import { FirestoreMetadata } from '../../types/firestore/FirestoreMetadata';
import { SmashNote } from '../../types/smash/SmashNote';

export enum NotesActionType {
  ADD_NOTE = 'notes/ADD_NOTE',
  NOTES_REQUESTED = 'notes/NOTES_REQUESTED',
  NOTE_REQUESTED = 'notes/NOTE_REQUESTED',
  RECEIVE_NOTES = 'notes/RECEIVE_NOTES',
  RECEIVE_NOTE = 'notes/RECEIVE_NOTE',
  DELETE_NOTE = 'notes/DELETE_NOTE',
}

export interface AddNoteAction {
  type: NotesActionType.ADD_NOTE;
  id: string;
  note: SmashNote;
}

export interface RequestNotesAction {
  type: NotesActionType.NOTES_REQUESTED;
}

export interface RequestNoteAction {
  type: NotesActionType.NOTE_REQUESTED;
}

export interface ReceiveNotesAction {
  type: NotesActionType.RECEIVE_NOTES;
  notes: {
    [key: string]: SmashNote & Partial<FirestoreMetadata>;
  };
}

export interface ReceiveNoteAction extends Action {
  type: NotesActionType.RECEIVE_NOTE;
  note: SmashNote & FirestoreMetadata;
}

export interface DeleteNoteAction {
  type: NotesActionType.DELETE_NOTE;
  id: string;
}

export type NotesAction =
  | RequestNotesAction
  | ReceiveNotesAction
  | ReceiveNoteAction
  | DeleteNoteAction
  | AddNoteAction
  | RequestNoteAction;
