import { User } from '@firebase/auth-types';
import { WithStyles, withStyles } from '@material-ui/core/styles/index';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { History, Location } from 'history';
import * as React from 'react';
import { default as Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, Dispatch } from 'redux';
import Loading from './components/basic/Loading';
import Navbar from './components/navigation/Navbar';
import SideMenu from './components/navigation/SideMenu';
import Loadable from './components/utils/Loadable';
import Notifier from './components/utils/Notifier';
import { default as Route } from './components/utils/RouteComponents';
import { default as Switch } from './components/utils/SwitchWithWindowTitle';
import { SubtractProps } from './library/helper-types';
import { loginActionCreator, logoutActionCreator } from './redux-modules/user/action-creators';
import { appTheme } from './Theme';
import { AppState } from './types/AppState';

const LandingPage = Loadable({ loader: () => import('./pages/LandingPage/LandingPage') });
const Login = Loadable({ loader: () => import('./pages/Login') });
const ForgotPassword = Loadable({ loader: () => import('./pages/ForgotPassword') });
const Register = Loadable({ loader: () => import('./pages/Register') });
const Dashboard = Loadable({ loader: () => import('./pages/protected/Dashboard') });
const ViewGames = Loadable({ loader: () => import('./pages/protected/Games/ViewGames') });
const ViewNotes = Loadable({ loader: () => import('./pages/protected/Notes/ViewNotes') });
const ViewOpponents = Loadable({
  loader: () => import('./pages/protected/Opponents/ViewOpponents'),
});
const AddGame = Loadable({ loader: () => import('./pages/protected/Games/AddGame') });
const AddNote = Loadable({ loader: () => import('./pages/protected/Notes/AddNote') });
const EditGame = Loadable({ loader: () => import('./pages/protected/Games/EditGame') });
const EditNote = Loadable({ loader: () => import('./pages/protected/Notes/EditNote') });
const Account = Loadable({ loader: () => import('./pages/protected/Account') });

const styles = (theme: typeof appTheme) => ({
  app: {
    color: theme.palette && theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
  },
  viewContainer: {
    display: 'flex',
    justifyContent: 'center' as 'center',
    margin: theme.spacing.unit,
  },
});

type Props = {
  history: History;
  location: Location;
  userLogin: (user: User) => void;
  userLogout: () => void;
} & WithStyles<typeof styles>;

type State = {
  loading: boolean;
};

export class DumbApp extends React.Component<Props, State> {
  removeAuthStateListener?: () => void;
  state = {
    loading: true,
  };
  render = () => {
    if (this.state.loading) return <Loading />;

    const { location, classes } = this.props;
    return (
      <div className={classes.app}>
        <SideMenu />
        <Notifier />
        <Navbar />
        <div
          style={{
            position: 'fixed',
            top: '65px',
            bottom: '0px',
            left: '0px',
            right: '0px',
          }}
        >
          <Scrollbars autoHide hideTracksWhenNotNeeded>
            <main className={classes.viewContainer}>
              <Switch
                currentPath={location ? location.pathname : ''}
                defaultWindowTitle='smashdex'
                matchedRoutePrefix='smashdex | '
              >
                <Route.Any path='/' exact component={LandingPage} />
                <Route.Public path='/login' title='login' component={Login} />
                <Route.Public
                  path='/forgotpassword'
                  title='forgot password'
                  component={ForgotPassword}
                />
                <Route.Public path='/register' title='register' component={Register} />
                <Route.Private path='/dashboard' title='dashboard' component={Dashboard} />
                <Route.Private exact path='/account' title='account' component={Account} />
                <Route.Private exact path='/notes' title='notes' component={ViewNotes} />
                <Route.Private exact path='/games' title='games' component={ViewGames} />
                {/*<Route.Private*/}
                  {/*exact*/}
                  {/*path={opponentDomain.viewList.path}*/}
                  {/*title={opponentDomain.viewList.title}*/}
                  {/*component={opponentDomain.viewList.component}*/}
                {/*/>*/}
                <Route.Private exact path='/games/new' title='adding game' component={AddGame} />
                <Route.Private exact path='/notes/new' title='adding note' component={AddNote} />
                <Route.Private path='/games/:matchId/edit' title='editing game' component={EditGame} />
                <Route.Private path='/notes/:noteId/edit' title='editing note' component={EditNote} />
                <Route.Any title='Game Detail' component={() => <h3>Page not found</h3>} />
              </Switch>
            </main>
          </Scrollbars>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.removeAuthStateListener = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.props.userLogin(user);
        this.setState({ loading: false });
      } else {
        this.props.userLogout();
        this.setState({ loading: false });
      }
    });
  }

  componentWillUnmount() {
    if (this.removeAuthStateListener) this.removeAuthStateListener();
  }
}

const mapStateToProps = (state: AppState): Partial<Props> => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    userLogin: (user: User) => {
      dispatch(loginActionCreator(user));
    },
    userLogout: () => {
      dispatch(logoutActionCreator());
    },
  };
};

const styledApp = withStyles(styles)(DumbApp);

type OwnProps = SubtractProps<
  Props,
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  typeof styles
>;

export default compose<React.ComponentClass<OwnProps>>(
  /* tslint:disable-next-line:max-line-length */
  // github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(styledApp);
