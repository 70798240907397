import { WithStyles, withStyles } from '@material-ui/core/styles/index';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import Logo from '../../components/basic/Logo';
import ProfileButton from '../../components/navigation/ProfileButton';
import { SubtractProps } from '../../library/helper-types';
import * as routes from '../../routes';
import AppConstants from '../../types/AppConstants';
import { AppState } from '../../types/AppState';
import NavLink from './NavLink';

const styles = {
  flexGrow: {
    flexGrow: 1,
  },
  navbarHeader: {
    color: 'inherit',
    textDecoration: 'none',
    fontFamily: 'Advent Pro',
    letterSpacing: '1.5vw',
    textShadow: '0 0 10px #fff',
    fontSize: '1.2rem',
    display: 'flex',
    alignItems: 'center',
  },
};

type Props = {
  authed: boolean;
  currentPathname: string;
};

const DesktopNavbar = ({ authed, currentPathname, classes }: Props & WithStyles<typeof styles>) => {
  let firstBreadcrumb: string | false =
    currentPathname === '/' ? '/' : currentPathname.split('/')[1];

  if (authed) {
    if (!['dashboard', 'games', 'notes'].includes(firstBreadcrumb)) {
      firstBreadcrumb = false;
    }

    return (
      <Toolbar>
        <Link to={routes.landingPage.path} className={classes.navbarHeader}>
          <Logo />
          {AppConstants.appTitle}
        </Link>
        <Tabs value={firstBreadcrumb} className={classes.flexGrow}>
          <NavLink
            to={routes.dashboard.path}
            value={routes.dashboard.label.toLowerCase()}
            label={routes.dashboard.label}
          />
          <NavLink
            to={routes.viewGames.path}
            value={routes.viewGames.label.toLowerCase()}
            label={routes.viewGames.label}
          />
          <NavLink
            to={routes.viewNotes.path}
            value={routes.viewNotes.label.toLowerCase()}
            label={routes.viewNotes.label}
          />
          {/*<NavLink*/}
            {/*to={routes.opponentDomain.viewList.path}*/}
            {/*value={routes.opponentDomain.viewList.label.toLowerCase()}*/}
            {/*label={routes.opponentDomain.viewList.label}*/}
          {/*/>*/}
        </Tabs>
        <ProfileButton />
      </Toolbar>
    );
  }

  if (!['login', 'register'].includes(firstBreadcrumb)) firstBreadcrumb = false;

  return (
    <Toolbar>
      <Link to='/' className={classes.navbarHeader}>
        <Logo />
        {AppConstants.appTitle}
      </Link>
      <div className={classes.flexGrow} />
      <Tabs value={firstBreadcrumb}>
        <NavLink
          to={routes.login.path}
          value={routes.login.label.toLowerCase()}
          label={routes.login.label}
        />
        <NavLink
          to={routes.register.path}
          value={routes.register.label.toLowerCase()}
          label={routes.register.label}
        />
      </Tabs>
    </Toolbar>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    authed: state.user.authed,
    currentPathname: state.router.location.pathname,
  };
};

type OwnProps = SubtractProps<Props, typeof mapStateToProps>;

export default compose<React.ComponentClass<OwnProps>>(
  withStyles(styles),
  connect(mapStateToProps),
)(DesktopNavbar);
