import { FirestoreMetadata } from '../../types/firestore/FirestoreMetadata';
import { Opponent } from '../../types/smash/Opponent';

export enum OpponentsActionType {
  REQUEST_OPPONENTS = 'opponents/REQUEST_OPPONENTS',
  REQUEST_OPPONENT = 'opponents/REQUEST_OPPONENT',
  ADD_OPPONENT = 'opponents/ADD_OPPONENT',
  RECEIVE_OPPONENT = 'opponents/RECEIVE_OPPONENT',
  RECEIVE_OPPONENTS = 'opponents/RECEIVE_OPPONENTS',
  UPDATE_OPPONENT = 'opponents/UPDATE_OPPONENT',
  DELETE_OPPONENT = 'opponents/DELETE_OPPONENT',
}

export interface RequestOpponentsAction {
  type: OpponentsActionType.REQUEST_OPPONENTS;
}

export interface RequestOpponentAction {
  type: OpponentsActionType.REQUEST_OPPONENT;
  id: string;
}

export interface ReceiveOpponentAction {
  type: OpponentsActionType.RECEIVE_OPPONENT;
  opponent: Opponent & FirestoreMetadata;
}

export interface ReceiveOpponentsAction {
  type: OpponentsActionType.RECEIVE_OPPONENTS;
  opponents: {
    [key: string]: Opponent & FirestoreMetadata;
  };
}

// export interface UpdateOpponentAction {
//   type: OpponentsActionType.UPDATE_OPPONENT;
// }
//
// export interface AddOpponentAction {
//   type: OpponentsActionType.ADD_OPPONENT;
// }
//
export interface DeleteOpponentAction {
  type: OpponentsActionType.DELETE_OPPONENT;
  id: string;
}

export type OpponentsAction =
  | RequestOpponentsAction
  | RequestOpponentAction
  | ReceiveOpponentsAction
  // | UpdateOpponentAction
  // | AddOpponentAction
  // | DeleteOpponentAction
  | ReceiveOpponentAction;
