import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AppState } from '../../types/AppState';

type RouteComponentProps = {
  component: React.ComponentClass | React.FunctionComponent;
  title?: string;
} & RouteProps;

type AuthDependentRouteComponentProps = {
  authed: boolean;
} & RouteComponentProps;

const Any = (props: RouteComponentProps) => {
  return <Route {...props} />;
};

const Private = ({ component: Component, authed, ...rest }: AuthDependentRouteComponentProps) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

const Public = ({ component: Component, authed, ...rest }: AuthDependentRouteComponentProps) => {
  return (
    <Route
      {...rest}
      render={(props) => (!authed ? <Component {...props} /> : <Redirect to='/dashboard' />)}
    />
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    authed: state.user.authed,
  };
};

const ConnectedPrivate = connect(mapStateToProps)(Private);
const ConnectedPublic = connect(mapStateToProps)(Public);

export default {
  Any,
  Private: ConnectedPrivate,
  Public: ConnectedPublic,
};
