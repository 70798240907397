import { createBrowserHistory } from 'history';
import * as React from 'react';
import { render } from 'react-dom';
import App from './App';
import { Providers } from './Providers';
import { initialState } from './redux-modules';
import * as serviceWorker from './service-worker';
import './setup/firebase-init';
import { configureStore } from './setup/redux-store/configure-redux-store';

const target = document.getElementById('root');

const history = createBrowserHistory();
const store = configureStore(initialState, history);

render(
  <Providers
    history={history}
    store={store}
  >
    <App />
  </Providers>,
  target,
);

// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

interface Window {
  deployedBranchHash?: string;
}

(window as Window)['deployedBranchHash'] = process.env.REACT_APP_BRANCH_HASH;
