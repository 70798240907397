import * as firebase from 'firebase/app';
import { assertNever } from '../../library/helper-types';
import { FirestoreMetadata } from '../../types/firestore/FirestoreMetadata';
import { SmashNote } from '../../types/smash/SmashNote';
import { NotesAction, NotesActionType } from './actions';

export type State = {
  notes: {
    [key: string]: SmashNote & Partial<FirestoreMetadata>;
  };
  isFetchingAll: boolean;
  isFetchingOne: boolean;
  timeLastReceived?: firebase.firestore.Timestamp;
};

const initialState: State = {
  notes: {},
  isFetchingAll: false,
  isFetchingOne: false,
  timeLastReceived: firebase.firestore.Timestamp.fromDate(new Date(1970)),
};

export default (state: State = initialState, action: NotesAction): State => {
  switch (action.type) {
    case NotesActionType.NOTE_REQUESTED:
      return {
        ...state,
        isFetchingOne: true,
      };
    case NotesActionType.NOTES_REQUESTED:
      return {
        ...state,
        isFetchingAll: true,
      };

    case NotesActionType.RECEIVE_NOTES:
      return {
        ...state,
        notes: {
          ...state.notes,
          ...action.notes,
        },
        isFetchingAll: false,
        timeLastReceived: firebase.firestore.Timestamp.now(),
      };

    case NotesActionType.RECEIVE_NOTE:
      return {
        ...state,
        notes: {
          ...state.notes,
          [action.note.id!]: action.note,
        },
        isFetchingOne: false,
      };

    case NotesActionType.DELETE_NOTE:
      const notes = {
        ...state.notes,
      };

      delete notes[action.id];
      return {
        ...state,
        notes,
      };

    case NotesActionType.ADD_NOTE:
      return {
        ...state,
        notes: {
          ...state.notes,
          [action.id]: action.note,
        },
      };

    default:
      try {
        return assertNever(action);
      } catch (e) {}
      return state;
  }
};
