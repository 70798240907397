import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, WithStyles } from '@material-ui/core/styles/index';
import * as React from 'react';
import { appTheme } from '../../Theme';

const styles = (theme: typeof appTheme) => ({
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
});

type Props = {
  backdrop?: boolean;
} & WithStyles<typeof styles>;

const Loading = ({ classes, backdrop }: Props) => {
  return (
    <div className={classes.loading}>
      <CircularProgress />
      {backdrop === true || typeof backdrop === 'undefined' ? <Backdrop open /> : null}
    </div>
  );
};

export default withStyles(styles)(Loading);
