import { StyleRulesCallback, WithStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { RouteComponentProps } from 'react-router';

interface IProps {
  [key: string]: any;
}

export type Omit<T, K extends (...args: any) => any> = T extends any
  ? Pick<T, Exclude<keyof T, K>>
  : never;

export type SubtractObjectProps<PROPS extends IProps, T extends {}> = T extends any
  ? Pick<T, Exclude<keyof PROPS, T>>
  : never;

export type SubtractReturnProps<
  PROPS extends IProps,
  T extends (...args: any) => any
> = PROPS extends any ? Pick<PROPS, Exclude<keyof PROPS, keyof ReturnType<T>>> : never;

type SubtractRouterProps<PROPS extends IProps> = SubtractObjectProps<
  PROPS,
  keyof RouteComponentProps
>;

type WithStylesTypeConstraint = string | Record<string, CSSProperties> | StyleRulesCallback<string>;

export type SubtractStyleProps<
  PROPS extends IProps,
  S extends WithStylesTypeConstraint
> = SubtractObjectProps<PROPS, keyof WithStyles<S>>;

type Function = (...args: any) => any;

type PropInjector = Function | {} | undefined;

type ApplyInjector<PROPS extends IProps, T extends PropInjector = undefined> = T extends Function
  ? SubtractReturnProps<PROPS, T>
  : T extends {}
  ? SubtractObjectProps<PROPS, T>
  : T extends WithStylesTypeConstraint
  ? SubtractStyleProps<PROPS, T>
  : T extends undefined
  ? PROPS
  : never;

export type SubtractProps<
  PROPS extends IProps,
  T extends PropInjector,
  U extends PropInjector = undefined,
  V extends PropInjector = undefined,
  W extends PropInjector = undefined,
  X extends PropInjector = undefined,
  Y extends PropInjector = undefined,
  Z extends PropInjector = undefined
> = ApplyInjector<ApplyInjector<ApplyInjector<PROPS, T>, U>, V>;

export function assertNever(x: never): never {
  throw new Error();
}
