import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import games from './games';
import notes from './notes';
import opponents from './opponents';
import ui from './ui';
import user from './user';

export const initialState = {
  router: null,
  user: { authed: false },
};

export const createRootReducer = (history: History<any>) =>
  combineReducers({
    games,
    user,
    notes,
    opponents,
    ui,
    router: connectRouter(history),
  });
