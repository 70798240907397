import * as firebase from 'firebase/app';

interface Window {
  googleAnalytics?: UniversalAnalytics.ga;
  smashdexDebug?: boolean;
}

const { googleAnalytics, smashdexDebug } = window as Window;

export const logView = (screenName: string, path: string) => {
  const currentUser = firebase.auth().currentUser;
  const user = currentUser ? currentUser.toJSON() : null;

  const eventObject = {
    user,
    screen_name: screenName,
  };

  if (smashdexDebug) {
    if (process.env.NODE_ENV === 'production') {
      if (googleAnalytics) {
        googleAnalytics('event', 'screen_view', eventObject);
      }
    }

    console.log('event', 'screen_view', eventObject);
  }
};
