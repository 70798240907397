import { default as AccountIcon } from '@material-ui/icons/AccountCircle';
import { default as AssessmentIcon } from '@material-ui/icons/Assessment';
import { default as BookIcon } from '@material-ui/icons/Book';
import { default as HomeIcon } from '@material-ui/icons/Home';
import { default as GamepadIcon } from '@material-ui/icons/VideogameAsset';
import * as React from 'react';
import Loadable from './components/utils/Loadable';

export type Route = {
  label?: string;
  icon?: React.Component;
  component: typeof React.Component;
  path: string;
  allowAccessWhenAuthenticated: boolean;
  allowAccessWhenNotAuthenticated: boolean;
};

export const landingPage: any = {
  label: 'Home',
  icon: <HomeIcon />,
  component: Loadable({ loader: () => import('./pages/LandingPage/LandingPage') }),
  path: '/',
  allowAccessWhenAuthenticated: false,
  allowAccessWhenNotAuthenticated: true,
};

export const login: any = {
  label: 'Login',
  component: Loadable({ loader: () => import('./pages/Login') }),
  path: '/login',
  allowAccessWhenAuthenticated: false,
  allowAccessWhenNotAuthenticated: true,
};

export const forgotPassword: any = {
  label: 'Forgot Password',
  component: Loadable({ loader: () => import('./pages/ForgotPassword') }),
  path: '/forgotpassword',
  allowAccessWhenAuthenticated: false,
  allowAccessWhenNotAuthenticated: true,
};

export const register: any = {
  label: 'Register',
  component: Loadable({ loader: () => import('./pages/Register') }),
  path: '/register',
  allowAccessWhenAuthenticated: false,
  allowAccessWhenNotAuthenticated: true,
};

export const dashboard: any = {
  label: 'Dashboard',
  icon: <AssessmentIcon />,
  component: Loadable({ loader: () => import('./pages/protected/Dashboard') }),
  path: '/dashboard',
  allowAccessWhenAuthenticated: true,
  allowAccessWhenNotAuthenticated: false,
};

export const viewGames: any = {
  label: 'Games',
  icon: <GamepadIcon />,
  component: Loadable({ loader: () => import('./pages/protected/Dashboard') }),
  path: '/games',
  allowAccessWhenAuthenticated: true,
  allowAccessWhenNotAuthenticated: false,
};

export const viewNotes: any = {
  label: 'Notes',
  icon: <BookIcon />,
  component: Loadable({
    loader: () => import('./pages/protected/Notes/ViewNotes'),
  }),
  path: '/notes',
  allowAccessWhenAuthenticated: true,
  allowAccessWhenNotAuthenticated: false,
};

export const addGame: any = {
  label: 'Add Single Game',
  component: Loadable({
    loader: () => import('./pages/protected/Games/AddGame'),
  }),
  path: '/games/new',
  allowAccessWhenAuthenticated: true,
  allowAccessWhenNotAuthenticated: false,
};

export const addNote: any = {
  label: 'Add Smash Note',
  component: Loadable({
    loader: () => import('./pages/protected/Notes/AddNote'),
  }),
  path: '/notes/new',
  allowAccessWhenAuthenticated: true,
  allowAccessWhenNotAuthenticated: false,
};

export const editGame: any = {
  label: 'Edit Single Game',
  component: Loadable({
    loader: () => import('./pages/protected/Games/EditGame'),
  }),
  path: '/games/:matchId/edit',
  allowAccessWhenAuthenticated: true,
  allowAccessWhenNotAuthenticated: false,
};

export const editNote: any = {
  label: 'Edit Smash Note',
  component: Loadable({
    loader: () => import('./pages/protected/Notes/EditNote'),
  }),
  path: '/notes/:noteId/edit',
  allowAccessWhenAuthenticated: true,
  allowAccessWhenNotAuthenticated: false,
};

// export const opponentDomain: any = {
//   path: '/opponents',
//   viewList: {
//     label: 'Opponents',
//     icon: <BookIcon />,
//     component: Loadable({
//       loader: () => import('./pages/protected/Opponents/ViewOpponents'),
//     }),
//     path: '/opponents',
//     allowAccessWhenAuthenticated: true,
//     allowAccessWhenNotAuthenticated: false,
//   },
// };

export const account: any = {
  label: 'Account',
  icon: <AccountIcon />,
  component: Loadable({
    loader: () => import('./pages/protected/Account'),
  }),
  path: '/account',
  allowAccessWhenAuthenticated: true,
  allowAccessWhenNotAuthenticated: false,
};

type NavigationBarObject = {
  label: string;
};

export type NavigationBarLink = NavigationBarObject & {
  path: string;
};

export type NavigationBarButton = NavigationBarObject & {
  onClick: () => any;
};
