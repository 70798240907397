import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import * as React from 'react';
import { compose } from 'redux';
import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';

const styles = {
  root: {
    background: 'linear-gradient(140deg, #002f26, #065972)',
    height: '65px',
  },
};

type OwnProps = {};

type Props = OwnProps & WithStyles<typeof styles>;

const Navbar = (props: Props) => {
  return (
    <AppBar position='sticky' className={props.classes.root}>
      <Hidden mdUp>
        <MobileNavbar />
      </Hidden>
      <Hidden smDown>
        <DesktopNavbar />
      </Hidden>
    </AppBar>
  );
};

export default compose<React.ComponentClass<OwnProps>>(
  withWidth(),
  withStyles(styles),
)(Navbar);
