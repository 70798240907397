import { withSnackbar } from 'notistack';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ExecutableTimeout } from '../../library/executable-timeout';
import { removeNotificationFromSnackbarQueueActionCreator } from '../../redux-modules/ui/action-creators';
import { AppState } from '../../types/AppState';
import { SnackbarNotification } from '../../types/SnackbarNotification';

type Props = {
  notifications: SnackbarNotification[];
  enqueueSnackbar: (message: string, options: {}) => void;
  removeSnackbar: (key: number) => void;
};

type State = {
  displayed: number[];
};

class Notifier extends React.Component<Props, State> {
  state = {
    displayed: [],
  };

  timeouts: ExecutableTimeout[] = [];

  storeDisplayed = (key: number) => {
    this.setState(({ displayed }) => ({
      displayed: [...displayed, key],
    }));
  }

  render() {
    const { notifications, enqueueSnackbar, removeSnackbar } = this.props;
    const { displayed } = this.state;

    notifications.forEach((notification) => {
      const timeout = new ExecutableTimeout(
        (timeoutNotification, timeoutDisplayed) => {
          // If notification already displayed, abort
          if (timeoutDisplayed.indexOf(timeoutNotification.key) > -1) return;
          // Display notification using notistack
          enqueueSnackbar(timeoutNotification.message, timeoutNotification.options);
          // Add notification's key to the local state
          this.storeDisplayed(timeoutNotification.key);
          // Dispatch action to remove the notification from the redux store
          removeSnackbar(timeoutNotification.key);
        },
        1,
        notification,
        displayed,
      );

      this.timeouts = [...this.timeouts, timeout];
    });

    return null;
  }

  componentWillUnmount(): void {
    for (const timeout of this.timeouts) {
      timeout.executeNow();
    }
  }
}

const mapStateToProps = (store: AppState) => ({
  notifications: store.ui.notifications,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ removeSnackbar: removeNotificationFromSnackbarQueueActionCreator }, dispatch);

export default withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Notifier),
);
