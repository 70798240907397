import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Dispatch } from 'redux';
import {
  addErrorNotificationToSnackbarQueueActionCreator,
  addNotificationToSnackbarQueueActionCreator,
} from '../redux-modules/ui/action-creators';
import {
  logoutActionCreator,
  receiveAccountInfoActionCreator,
  receiveEmailAddressActionCreator,
  requestAccountInfoActionCreator,
  UserInfo,
} from '../redux-modules/user/action-creators';
import { GetState } from '../types/Store';

const getUsersCollection = () => firebase.firestore().collection('users');

export function logout() {
  return async (dispatch: Dispatch, getState: GetState) => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(logoutActionCreator());
      });
  };
}

export function register(gamertag: string, email: string, password: string) {
  return async (dispatch: Dispatch) => {
    const userCredentials = await firebase.auth().createUserWithEmailAndPassword(email, password);
    const { user } = userCredentials;

    if (user === null) throw Error('There was a problem registering the user.');

    user.sendEmailVerification().catch(console.log);

    const accountInfo = { gamertag };

    await getUsersCollection()
      .doc(`${user.uid}`)
      .set(accountInfo);

    dispatch(receiveAccountInfoActionCreator(accountInfo));

    sendVerificationEmail();
  };
}

export function getAccountInfo() {
  return async (dispatch: Dispatch) => {
    dispatch(requestAccountInfoActionCreator());
    const { currentUser } = firebase.auth();
    if (currentUser === null) throw Error('User is null');
    const userUid = currentUser.uid;

    const documentSnapshot = await getUsersCollection()
      .doc(`${userUid}`)
      .get();
    if (documentSnapshot.exists) {
      const accountInfo = documentSnapshot.data() as UserInfo;

      dispatch(receiveAccountInfoActionCreator(accountInfo));

      return accountInfo;
    }

    return {};
  };
}

export function updateEmailAddress(emailAddress: string) {
  return async (dispatch: Dispatch) => {
    const user = firebase.auth().currentUser;

    if (user === null) throw Error('User is null');

    await user
      .updateEmail(emailAddress)
      .then(() => {
        dispatch(addNotificationToSnackbarQueueActionCreator('Email Address updated'));
        return dispatch(receiveEmailAddressActionCreator(emailAddress));
      })
      .catch((error) => {
        dispatch(addErrorNotificationToSnackbarQueueActionCreator(error.message));
      });
  };
}

export function updateGamertag(gamertag: string) {
  return async (dispatch: Dispatch) => {
    const user = firebase.auth().currentUser;

    if (user === null) throw Error('User is null');

    const accountInfo = { gamertag };

    await getUsersCollection()
      .doc(`${user.uid}`)
      .set(accountInfo);

    dispatch(addNotificationToSnackbarQueueActionCreator('Gamertag updated'));

    dispatch(receiveAccountInfoActionCreator(accountInfo));
  };
}

export function deleteAccount() {
  return async (dispatch: Dispatch) => {
    const user = firebase.auth().currentUser;

    if (user === null) throw Error('User is null');

    await user.delete();

    dispatch(logoutActionCreator());
  };
}

export function sendVerificationEmail() {
  return async () => {
    const user = firebase.auth().currentUser;

    if (user === null) throw Error('User is null');

    await user.sendEmailVerification();
  };
}

export function updatePassword(emailAddress: string, oldPassword: string, newPassword: string) {
  return async (dispatch: Dispatch) => {
    await firebase
      .auth()
      .signInWithEmailAndPassword(emailAddress, oldPassword)
      .then(async () => {
        const user = firebase.auth().currentUser;
        if (user === null) throw Error('User is null');
        await user.updatePassword(newPassword);
      })
      .then(() => {
        dispatch(addNotificationToSnackbarQueueActionCreator('Password changed'));
      })
      .catch((error) => {
        dispatch(addErrorNotificationToSnackbarQueueActionCreator(error.message));
      });
  };
}

export function login(email: string, password: string) {
  const trimmedEmail = email.trim();

  return firebase.auth().signInWithEmailAndPassword(trimmedEmail, password);
}

export function resetPassword(email: string) {
  return firebase.auth().sendPasswordResetEmail(email);
}
