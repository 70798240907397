import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as React from 'react';

/* tslint:disable-next-line:max-line-length */
// https://material.io/tools/color/#!/?view.left=1&view.right=1&primary.color=007254&secondary.color=7b57d3&primary.text.color=ffffff

export const appTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      light: '#ae85ff',
      main: '#7b57d3',
      dark: '#472ca1',
      contrastText: '#f5f5f5',
    },
    secondary: {
      light: '#03b2a3',
      main: '#02786F',
      dark: '#015951',
      contrastText: '#f5f5f5',
    },
    common: {
      white: '#f5f5f5',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(255, 255, 255, 0.54)',
    },
    background: {
      default: '#007254',
      // default: "",
    },
  },
  typography: {
    useNextVariants: true,
    h1: {
      fontFamily: 'Advent Pro,Helvetica Neue,Arial,Helvetica,sans-serif',
    },
    h2: {
      fontFamily: 'Advent Pro,Helvetica Neue,Arial,Helvetica,sans-serif',
    },
    h3: {
      fontFamily: 'Advent Pro,Helvetica Neue,Arial,Helvetica,sans-serif',
    },
    h4: {
      fontFamily: 'Advent Pro,Helvetica Neue,Arial,Helvetica,sans-serif',
    },
    h5: {
      fontSize: '2.125rem',
      fontFamily: 'Advent Pro,Helvetica Neue,Arial,Helvetica,sans-serif',
    },
    h6: {
      fontFamily: 'Advent Pro,Helvetica Neue,Arial,Helvetica,sans-serif',
    },
    body1: {
      lineHeight: 1.2,
      letterSpacing: '0.00838em',
    },
    body2: {
      lineHeight: 1.2,
      letterSpacing: '0.00971em',
    },
  },
});

type Props = {
  children: React.ReactChild;
};

const Theme = ({ children }: Props) => {
  return (
    <React.Fragment>
      <MuiThemeProvider theme={appTheme}>{children}</MuiThemeProvider>
    </React.Fragment>
  );
};

export default Theme;
