export class ExecutableTimeout {
  private readonly id: NodeJS.Timeout;

  constructor(
    private readonly functionToCall: (...args: any) => any,
    private readonly delayInMilliseconds: number,
    ...args: any[]
  ) {
    this.id = setTimeout(functionToCall, delayInMilliseconds, ...args);
  }

  clear = () => {
    clearTimeout(this.id);
  }

  executeNow = () => {
    this.clear();
    this.functionToCall();
  }
}
