import Tab from '@material-ui/core/Tab';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { setSidemenuVisibilityActionCreator } from '../../redux-modules/ui/action-creators';
import { AppState } from '../../types/AppState';

type Props = {
  to: string;
  navigateTo: (path: string) => void;
  label: string;
};

const NavLink = ({ to, navigateTo, label }: Props) => {
  return <Tab label={label} onClick={() => navigateTo(to)} />;
};

const mapStateToProps = (state: AppState, ownProps: Props & RouteComponentProps<{}>) => {
  return {
    history: state.router,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    navigateTo: (to: string) => {
      dispatch(setSidemenuVisibilityActionCreator(false));
      dispatch(push(to));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavLink);
