import { CssBaseline } from '@material-ui/core';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import * as React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import SnackbarProvider from './components/utils/SnackbarProvider';
import Theme from './Theme';

type Props = {
  children: React.ReactNode;
  history: History;
  store: ReturnType<typeof createStore>;
};

export const Providers = ({ children, store, history }: Props) => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <CssBaseline>
          <Theme>
            <SnackbarProvider>{children}</SnackbarProvider>
          </Theme>
        </CssBaseline>
      </ConnectedRouter>
    </Provider>
  );
};
