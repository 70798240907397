import { withStyles } from '@material-ui/core/styles/index';
import * as React from 'react';
import { default as Loadable } from 'react-loadable';
import Loading from '../basic/Loading';

const styles = {
  backdrop: {
    height: 'calc(100vh - 65px - 41px)',
  },
};

const LoadableComponent = ({
  error,
  pastDelay,
  classes,
}: {
  error: Error;
  pastDelay: boolean;
  classes: { backdrop: string };
}) => {
  if (error) {
    return <div>Error loading the page</div>;
  }
  if (pastDelay) {
    return (
      <div className={classes.backdrop}>
        <Loading />
      </div>
    );
  }
  return null;
};

type loaderObject<T> = {
  loader: () => Promise<{}>;
};

export default (opts: loaderObject<any>) => {
  const options = Object.assign(
    {
      loading: withStyles(styles)(LoadableComponent),
    },
    opts,
  );
  return Loadable(options as Loadable.Options<{}, {}>);
};
