import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { logout } from '../../async-actions/user';

const styles = {
  profileButton: {
    opacity: 0.95,
  },
};

type Props = {
  push: (path: string) => void;
  logout: () => Promise<void>;
};

class ProfileButton extends React.Component<Props & WithStyles<typeof styles>> {
  state = {
    auth: true,
    anchorEl: null,
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ auth: event.target.checked });
  }

  handleMenu = (event: React.MouseEvent<HTMLInputElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  }

  goToAccountInfo = () => {
    this.handleClose();
    this.props.push('/account');
  }

  logOut = async () => {
    await this.props.logout();
    this.handleClose;
  }

  render = () => {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton
          aria-owns={open ? 'menu-appbar' : undefined}
          aria-haspopup='true'
          onClick={this.handleMenu}
          color='inherit'
          className={classes.profileButton}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id='menu-appbar'
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.goToAccountInfo}>Account</MenuItem>
          {/*<MenuItem onClick={this.handleClose}>Settings</MenuItem>*/}
          {/*<Divider />*/}
          <MenuItem onClick={this.logOut}>Log Out</MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapDispatchToProps = {
  logout,
  push,
};

export default compose<React.ComponentClass>(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps,
  ),
)(ProfileButton);
