import { UiActionType } from './actions';

export const setSidemenuVisibilityActionCreator = (sidemenuVisible: boolean) => ({
  sidemenuVisible,
  type: UiActionType.SET_SIDEMENU_VISIBILITY,
});

export const addNotificationToSnackbarQueueActionCreator = (message: string, options: {} = {}) => ({
  notification: {
    message,
    options,
    id: new Date().getTime() + Math.random(),
  },
  type: UiActionType.ENQUEUE_SNACKBAR,
});

export const addErrorNotificationToSnackbarQueueActionCreator = (errorMessage: string, options: {} = {}) =>
  addNotificationToSnackbarQueueActionCreator(errorMessage, {
    ...options,
    variant: 'error',
  });

export const removeNotificationFromSnackbarQueueActionCreator = (key: number) => ({
  key,
  type: UiActionType.REMOVE_SNACKBAR,
});
