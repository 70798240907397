import { User } from '@firebase/auth-types';
import { UserActionType } from './actions';

export type UserInfo = {
  gamertag: string;
};

export const loginActionCreator = (user: User) => ({
  type: UserActionType.LOGIN,
  displayName: user.displayName,
  emailAddress: user.email,
  emailVerified: user.emailVerified,
  photoURL: user.photoURL,
});

export const logoutActionCreator = () => ({
  type: UserActionType.LOGOUT,
});

export const requestAccountInfoActionCreator = () => ({
  type: UserActionType.REQUEST_ACCOUNT_INFO,
});

export const receiveAccountInfoActionCreator = (accountInfo: UserInfo) => ({
  accountInfo,
  type: UserActionType.RECEIVE_ACCOUNT_INFO,
});

export const receiveEmailAddressActionCreator = (emailAddress: string) => ({
  emailAddress,
  type: UserActionType.RECEIVE_EMAIL_ADDRESS,
});
