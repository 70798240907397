import { assertNever } from '../../library/helper-types';
import { UserAction, UserActionType } from './actions';

export type State = {
  authed: boolean;
  isFetchingAccountInfo: boolean;
  displayName: string;
  gamertag: string;
  emailVerified: boolean;
  photoURL: string;
  emailAddress: string;
};

const initialState: State = {
  authed: false,
  isFetchingAccountInfo: false,
  displayName: '',
  gamertag: '',
  emailVerified: false,
  photoURL: '',
  emailAddress: '',
};

export default (state: State = initialState, action: UserAction) => {
  switch (action.type) {
    case UserActionType.LOGIN:
      const { displayName, emailVerified, photoURL } = action;
      return {
        ...state,
        displayName,
        emailVerified,
        photoURL,
        authed: true,
        emailAddress: action.emailAddress,
      };

    case UserActionType.LOGOUT:
      return {
        ...state,
        authed: false,
      };

    case UserActionType.REQUEST_ACCOUNT_INFO:
      return {
        ...state,
        isFetchingAccountInfo: true,
      };

    case UserActionType.RECEIVE_ACCOUNT_INFO:
      const { accountInfo } = action;
      return {
        ...state,
        isFetchingAccountInfo: false,
        gamertag: accountInfo.gamertag,
      };

    case UserActionType.RECEIVE_EMAIL_ADDRESS:
      return {
        ...state,
        emailAddress: action.emailAddress,
      };

    default:
      try {
        return assertNever(action);
      } catch (e) {}
      return state;
  }
};
