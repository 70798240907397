import * as firebase from 'firebase/app';
import { assertNever } from '../../library/helper-types';
import { FirestoreMetadata } from '../../types/firestore/FirestoreMetadata';
import { SingleGame } from '../../types/smash/SingleGame';
import { GamesAction, GamesActionType } from './actions';

export type State = {
  games: {
    [key: string]: SingleGame & Partial<FirestoreMetadata>;
  };
  isFetchingAll: boolean;
  timeLastReceived?: firebase.firestore.Timestamp;
};

const initialState = {
  games: {},
  isFetchingAll: false,
  timeLastReceived: undefined,
};

export default function games(state: State = initialState, action: GamesAction) {
  switch (action.type) {
    case GamesActionType.REQUEST_GAMES:
      return { ...state, isFetchingAll: true };

    case GamesActionType.REQUEST_GAME:
      return { ...state, isFetchingAll: true };

    case GamesActionType.DELETE_GAME:
      const games = {
        ...state.games,
      };

      delete games[action.id];
      return {
        ...state,
        games,
      };

    case GamesActionType.RECEIVE_GAME:
      return {
        ...state,
        games: {
          ...state.games,
          [action.game.id!]: action.game,
        },
        isFetchingAll: false,
      };

    case GamesActionType.ADD_GAME:
      return {
        ...state,
        games: {
          ...state.games,
          [action.id]: action.game,
        },
      };

    case GamesActionType.RECEIVE_GAMES:
      return {
        ...state,
        games: {
          ...state.games,
          ...action.games,
        },
        isFetchingAll: false,
        timeLastReceived: firebase.firestore.Timestamp.now(),
      };

    case GamesActionType.UPDATE_GAME:
      return {
        ...state,
        games: {
          ...state.games,
          [action.game.id!]: action.game,
        },
      };

    default:
      try {
        return assertNever(action);
      } catch (e) {}
      return state;
  }
}
