import { UserInfo } from './action-creators';

export enum UserActionType {
  LOGIN = 'user/LOGIN',
  LOGOUT = 'user/LOGOUT',
  RECEIVE_ACCOUNT_INFO = 'user/RECEIVE_ACCOUNT_INFO',
  RECEIVE_EMAIL_ADDRESS = 'user/RECEIVE_EMAIL_ADDRESS',
  REQUEST_ACCOUNT_INFO = 'user/REQUEST_ACCOUNT_INFO',
}

export interface LoginAction {
  type: UserActionType.LOGIN;
  displayName: string;
  emailVerified: boolean;
  photoURL: string;
  emailAddress: string;
}

export interface LogoutAction {
  type: UserActionType.LOGOUT;
}

export interface ReceiveAccountInfoAction {
  type: UserActionType.RECEIVE_ACCOUNT_INFO;
  accountInfo: UserInfo;
}

export interface ReceiveEmailAddressAction {
  type: UserActionType.RECEIVE_EMAIL_ADDRESS;
  emailAddress: string;
}

export interface RequestAccountInfoAction {
  type: UserActionType.REQUEST_ACCOUNT_INFO;
}

export type UserAction =
  | LoginAction
  | LogoutAction
  | ReceiveAccountInfoAction
  | ReceiveEmailAddressAction
  | RequestAccountInfoAction;
