import * as firebase from 'firebase/app';
import { assertNever } from '../../library/helper-types';
import { FirestoreMetadata } from '../../types/firestore/FirestoreMetadata';
import { Opponent } from '../../types/smash/Opponent';
import { OpponentsAction, OpponentsActionType } from './actions';

export type State = {
  opponents: {
    [key: string]: Opponent & Partial<FirestoreMetadata>;
  };
  isFetchingAll: boolean;
  fetchingIds: { [key: string]: true };
  timeLastReceived?: firebase.firestore.Timestamp;
};

const initialState = {
  opponents: {},
  fetchingIds: {},
  isFetchingAll: false,
  timeLastReceived: undefined,
};

export default function opponents(state: State = initialState, action: OpponentsAction) {
  switch (action.type) {
    case OpponentsActionType.REQUEST_OPPONENTS:
      return { ...state, isFetchingAll: true };

    case OpponentsActionType.REQUEST_OPPONENT:
      return {
        ...state,
        fetchingIds: {
          ...state.fetchingIds,
          [action.id]: true as true,
        },
      };

    case OpponentsActionType.RECEIVE_OPPONENT:
      const fetchingIds = {
        ...state.fetchingIds,
      };

      delete fetchingIds[action.opponent.id];
      return {
        ...state,
        fetchingIds,
        opponents: {
          ...state.opponents,
          ...{ [action.opponent.id]: action.opponent },
        },
      };

    case OpponentsActionType.RECEIVE_OPPONENTS:
      return {
        ...state,
        opponents: {
          ...state.opponents,
          ...action.opponents,
        },
        isFetchingAll: false,
        timeLastReceived: firebase.firestore.Timestamp.now(),
      };

    default:
      try {
        return assertNever(action);
      } catch (e) {}
      return state;
  }
}
