import { assertNever } from '../../library/helper-types';
import { SnackbarNotification } from '../../types/SnackbarNotification';
import { UiAction, UiActionType } from './actions';

export type State = {
  sidemenuVisible: boolean;
  notifications: SnackbarNotification[];
};

const initialState = {
  sidemenuVisible: false,
  notifications: [],
};

export default function ui(state: State = initialState, action: UiAction) {
  switch (action.type) {
    case UiActionType.SET_SIDEMENU_VISIBILITY:
      return {
        ...state,
        sidemenuVisible: action.sidemenuVisible,
      };

    case UiActionType.ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification,
          },
        ],
      };

    case UiActionType.REMOVE_SNACKBAR:
      const filteredNotifications = state.notifications.filter(
        (notification: SnackbarNotification) => notification.key !== action.key,
      );
      return {
        ...state,
        notifications: filteredNotifications,
      };

    default:
      try {
        return assertNever(action);
      } catch (e) {}
      return state;
  }
}
